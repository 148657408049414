<template lang="pug">
#app

  #banner

  NuxtLayout
    NuxtErrorBoundary(@error="onErrorCaptured")
      NuxtPage

  Notification

  #modals
</template>
<script setup lang="ts">
import { useGtm } from '@gtm-support/vue-gtm';
import { useAppScroll } from './store/appScroll'
import { useAuth } from './store/auth';

const { $sentry } = useNuxtApp()

const appScroll = useAppScroll()
const auth = useAuth()

onBeforeMount(() => {
  watch(() => appScroll.scrollEnabled, (scrollEnabled) => {
    if (scrollEnabled) {
      document.body.classList.remove('scroll-disabled')
    } else {
      document.body.classList.add('scroll-disabled')
    }
  })
})

// TODO: Make the auth store emit this event
onMounted(() => {
  if (auth.isAuthenticated) {
    gtmPush({ event: "captured-user-id" })
  }
})

useHead({
  title: "Box Magenta"
})

onErrorCaptured((error) => {
  console.error(error)
  $sentry.captureException(error)
})
</script>
<style lang="sass">
@import '~/assets/styles/mixins'
@import '~/assets/styles/variables.sass'

*
  box-sizing: border-box
  backface-visibility: hidden
  -webkit-backface-visibility: hidden
  -webkit-tap-highlight-color: transparent

html
  touch-action: manipulation
  background-color: #fff

body
  overflow: hidden auto

  &.scroll-disabled
    overflow-y: hidden

html, body
  position: relative
  width: 100%
  margin: 0
  padding: 0
  color: $textColor

  a
    text-decoration: none
    color: $textColor

  h1, h2, h3, h4
    font-family: "Spartan", sans-serif
    letter-spacing: -1px
    color: $headerColor

  span.magenta-text
    color: #EE3D8A

  #app
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    min-height: 100vh
    font-family: "Mulish", Arial, sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    color: $textColor
    box-sizing: border-box

@keyframes fade-in
  from
    opacity: 0

  to
    opacity: 1

</style>
