import * as Sentry from '@sentry/browser'

export function useSentry(): typeof Sentry | null {
  const { $sentry: sentry } = useNuxtApp()

  return sentry ?? null
}

export function captureException(error: Error): void {
  useSentry()?.captureException(error)

  console.error(error)
}

export function reportOnError(errorRef: Ref<Error | null>, message?: string): void {
  watch(errorRef, (error) => {
    if (error) {
      captureException(message ? new Error(message, { cause: error }) : error)
    }
  }, { immediate: true })
}