import type { Benefit } from '~/services/benefit/benefitService'
import type { ShippingAddress } from '~/services/shippingAddress/shippingAddressService'
import type { PrimarySubscription } from '~/services/subscription/subscriptionService'
import type { SubscriptionBoxOrder } from '~/services/subscription/subscriptionBoxOrder/subscriptionBoxOrderService'
import type { MemberGetMemberCoupon } from '~/services/subscription/memberGetMember/memberGetMember'

export default {
  async findSelfCustomer(): Promise<Customer> {
    return await apiGet('customer/self')
  },
  async findSelfInfo(): Promise<CustomerInfo> {
    return await apiGet('customer/self/info')
  },
  async findSelfPrimarySubscription(): Promise<PrimarySubscription> {
    return await apiGet(`customer/self/primary-subscription`)
  },
  async findSelfSubscriptionBoxOrders(): Promise<SubscriptionBoxOrder[]> {
    return await apiGet(`customer/self/box-orders`)
  },
  async findSelfShippingAddresses(): Promise<ShippingAddress[]> {
    return await apiGet(`customer/self/shipping-address`)
  },
  async findSelfPaymentProfiles(billingPlatformId: number): Promise<CustomerPaymentProfile[]> {
    return await apiGet(`customer/self/billing/${billingPlatformId}/payment-profile`)
  },
  async createSelfPaymentProfile(billingPlatformId: number, request: CreateSelfPaymentProfileRequest): Promise<CustomerPaymentProfile> {
    return await apiPost(`customer/self/billing/${billingPlatformId}/payment-profile`, { body: request })
  },
  async findSelfBenefits(): Promise<Benefit[]> {
    return await apiGet(`customer/self/benefits`)
  },
  async findSelfMemberGetMemberCoupon(): Promise<MemberGetMemberCoupon> {
    return await apiGet(`customer/self/mgm/coupon`)
  },
  async findPrimarySubscription(customerId: number): Promise<PrimarySubscription> {
    return await apiGet(`customer/${customerId}/primary-subscription`)
  },
  async findSubscriptionBoxOrders(customerId: number): Promise<SubscriptionBoxOrder[]> {
    return await apiGet(`customer/${customerId}/box-orders`)
  },
  async findBenefits(customerId: number): Promise<Benefit[]> {
    return await apiGet(`customer/${customerId}/benefits`)
  },
}

export interface Customer {
  id: number,
  firstName: string,
  lastName: string,
  birthdate: string,
  email: string,
  phoneNumber: string,
  cpf: string
}

export interface Influencer {
  id: number,
  customerId: number,
  shippingAddressId: number,
  promotedSkuId: string,
  coupons: string[],
  active: boolean,
  affiliate: boolean,
  createdAt: string,
  updatedAt: string
}

export interface CustomerInfo {
  customer: Customer,
  influencer: Influencer | null,
  affiliateDashboardUrl: string | null
}

export interface CustomerPaymentProfile {
  id: string,
  billingPlatformId: number,
  customerId: number,
  type: string,
  creditCard?: CustomerCreditCard,
  createdAt: string,
  updatedAt: string
}

export interface CustomerCreditCard {
  brand: string,
  displayNumber: string,
  bin: string,
  holderName: string,
  month: number,
  year: number
}

export interface CreateSelfPaymentProfileRequest {
  billingPlatformId: number,
  paymentProfileType: string,
  creditCardToken?: string,
}