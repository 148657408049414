import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin((nuxtApp) => {
  const gtmConfig = useConfig().gtm

  if (gtmConfig == undefined) {
    return
  }

  nuxtApp.vueApp.use(
    createGtm({
      id: gtmConfig.id,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: gtmConfig.debug,
      loadScript: true,
      vueRouter: useRouter(),
      trackOnNextTick: false,
    }))
})