import type { FetchError } from 'ofetch'
import type { NitroFetchOptions, NitroFetchRequest, AvailableRouterMethod } from 'nitropack'

import { useAuth } from '~/store/auth'

interface UserCredentials {
  id: number,
  email: string,
  exp: number,
  authenticationToken: string
}

const clearAuthToken = () => {
  useAuth().signOut()
}

const refreshAuthToken = async () => {
  await useAuth().refreshAuthToken()
}

const doWaffleFetch = <ResT = void, ReqT extends NitroFetchRequest = NitroFetchRequest, M extends AvailableRouterMethod<ReqT> = AvailableRouterMethod<ReqT>> (request: ReqT, options?: NitroFetchOptions<ReqT, M>, shouldRefreshAuthToken: boolean = false): Promise<ResT> => {
  const auth = useAuth()
  const config = useConfig()
  const authToken = auth.authToken

  const requestHeaders = (options?.headers ?? {})
  const requestOptions: NitroFetchOptions<ReqT, M> = {
    ...options,
    retry: false,
    baseURL: config.waffleApi,
    headers: {
      ...requestHeaders,
      ...(authToken ? { Authorization: `Bearer ${authToken}` } : {})
    }
  }

  // @ts-ignore
  return $fetch<ResT, ReqT>(request, requestOptions).catch(async (error: FetchError) => {
    if (auth.authToken && error.response?.status == 401 && shouldRefreshAuthToken) {
      try {
        await refreshAuthToken()

        return doWaffleFetch<ResT, ReqT, M>(request, options, false)
      } catch(e) {
        clearAuthToken()

        throw error
      }
    } else {
      throw error
    }
  })
}

export const waffleFetch = <ResT = void, ReqT extends NitroFetchRequest = NitroFetchRequest, M extends AvailableRouterMethod<ReqT> = AvailableRouterMethod<ReqT>> (request: ReqT, options?: NitroFetchOptions<ReqT, M>): Promise<ResT> =>
  doWaffleFetch(request, options, true)

export const apiGet = <ResT = void, ReqT extends NitroFetchRequest = NitroFetchRequest> (request: ReqT, options?: NitroFetchOptions<ReqT>): Promise<ResT> => {
  return waffleFetch<ResT, ReqT>(request, options)
}

export const apiPost = <ResT = void, ReqT extends NitroFetchRequest = NitroFetchRequest, M extends AvailableRouterMethod<ReqT> = 'post' extends AvailableRouterMethod<ReqT> ? 'post' : AvailableRouterMethod<ReqT>> (request: ReqT, options?: NitroFetchOptions<ReqT, M>): Promise<ResT> => {
  return waffleFetch<ResT, ReqT, M>(request, {
    ...(options ?? {}),
    method: 'POST' as M
  })
}

export const apiPatch = <ResT = void, ReqT extends NitroFetchRequest = NitroFetchRequest, M extends AvailableRouterMethod<ReqT> = 'patch' extends AvailableRouterMethod<ReqT> ? 'patch' : AvailableRouterMethod<ReqT>> (request: ReqT, options?: NitroFetchOptions<ReqT, M>): Promise<ResT> => {
  return waffleFetch<ResT, ReqT, M>(request, {
    ...(options ?? {}),
    method: 'PATCH' as M
  })
}

export const apiPut = <ResT = void, ReqT extends NitroFetchRequest = NitroFetchRequest, M extends AvailableRouterMethod<ReqT> = 'put' extends AvailableRouterMethod<ReqT> ? 'put' : AvailableRouterMethod<ReqT>> (request: ReqT, options?: NitroFetchOptions<ReqT, M>): Promise<ResT> => {
  return waffleFetch<ResT, ReqT, M>(request, {
    ...(options ?? {}),
    method: 'PUT' as M
  })
}

export const apiDelete = <ResT = void, ReqT extends NitroFetchRequest = NitroFetchRequest, M extends AvailableRouterMethod<ReqT> = 'delete' extends AvailableRouterMethod<ReqT> ? 'delete' : AvailableRouterMethod<ReqT>> (request: ReqT, options?: NitroFetchOptions<ReqT, M>): Promise<ResT> => {
  return waffleFetch<ResT, ReqT, M>(request, {
    ...(options ?? {}),
    method: 'DELETE' as M
  })
}

