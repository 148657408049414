import { useAuth } from '~/store/auth'

export default defineNuxtPlugin((nuxtApp) => {
  const auth = useAuth()
  const router = useRouter()

  router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !auth.isAuthenticated) {
      next({
        name: 'sign-in',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  })
})
