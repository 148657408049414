import type { Customer, Influencer } from '~/services/customer/customerService'

import CustomerService from '~/services/customer/customerService'

import { useAuth } from '~/store/auth'

export const useCustomerInfo = defineStore('customerInfo', () => {
  const auth = useAuth()

  const initialized = useState(() => false)
  const customer = useState<Customer | null>(() => null)
  const influencer = useState<Influencer | null>(() => null)
  const affiliateDashboardUrl = useState<string | null>(() => null)

  const refreshPromise = ref<Promise<void> | null>(null)

  const fetch = async () => {
    if (auth.isAuthenticated) {
      try {
        return  await CustomerService.findSelfInfo()
      } catch (error: any) {
        if ('status' in error && error.status != 404) {
          captureException(new Error('Failed to fetch customer info', { cause: error }))
        }

        return null
      }
    } else {
      return null
    }
  }

  const refresh = async () => {
    if (refreshPromise.value == null) {
      refreshPromise.value = fetch().then((customerInfo) => {
        if (customerInfo) {
          customer.value = customerInfo.customer
          influencer.value = customerInfo.influencer
          affiliateDashboardUrl.value = customerInfo.affiliateDashboardUrl
        }

        refreshPromise.value = null
      })
    }

    await refreshPromise.value
  }

  const init = async () => {
    if (!initialized.value) {
      await refresh()

      initialized.value = true
    }
  }

  watch(() => auth.userCredentials, refresh)

  return {
    init,
    customer,
    influencer,
    affiliateDashboardUrl,
    refresh
  }
})