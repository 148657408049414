import sha256 from "crypto-js/sha256"

import AuthService from '~/services/auth'

import { useGtm } from "@gtm-support/vue-gtm"

interface UserCredentials {
  id: number,
  email: string,
  exp: number,
  authenticationToken: string
}

const LOCAL_STORAGE_USER = "user"
const LOCAL_STORAGE_USER_EMAIL = "userEmail"
const LOCAL_STORAGE_USER_EMAIL_HASH = "userEmailHash"
const LOCAL_STORAGE_USER_ID = "userId"
const AUTH_TOKEN_COOKIE_NAME = "_bxmgntathtk"

const extractUserCredentialsFromJWT = (token: string): UserCredentials => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''))

  const payload = JSON.parse(jsonPayload)

  return {
    id: payload['user_id'] as number,
    email: payload['email'] as string,
    exp: payload['exp'] as number,
    authenticationToken: token as string
  }
}

const clearAuthTokenCookie = () => {
  const config = useRuntimeConfig()
  const expires = new Date(0)

  const domain = config.public.env == 'production' ?
    "boxmagenta.com.br" : (config.public.env == 'homolog' ? "homolog.boxmagenta.com.br" : undefined)

  const authTokenCookie = useCookie(AUTH_TOKEN_COOKIE_NAME, {
    path: '/',
    sameSite: 'strict',
    domain,
    expires
  })

  authTokenCookie.value = null
}

const storeAuthTokenCookie = (userCredentials: UserCredentials) => {
  const config = useRuntimeConfig()
  const expires = new Date(userCredentials.exp * 1000)

  const domain = config.public.env == 'production' ?
    "boxmagenta.com.br" : (config.public.env == 'homolog' ? "homolog.boxmagenta.com.br" : undefined)

  const authTokenCookie = useCookie(AUTH_TOKEN_COOKIE_NAME, {
    path: '/',
    sameSite: 'strict',
    domain,
    expires
  })

  authTokenCookie.value = userCredentials.authenticationToken
}

export const useAuth = defineStore('auth', () => {
  const userLocalStorage = useLocalStorage<string>(LOCAL_STORAGE_USER, null)
  const userEmailLocalStorage = useLocalStorage<string>(LOCAL_STORAGE_USER_EMAIL, null)
  const userEmailHashLocalStorage = useLocalStorage<string>(LOCAL_STORAGE_USER_EMAIL_HASH, null)
  const userIdLocalStorage = useLocalStorage<string>(LOCAL_STORAGE_USER_ID, null)

  const getClientAuthToken = computed((): string | null => {
    const userString = userLocalStorage.value

    if (userString != null) {
      const user = JSON.parse(userString) as UserCredentials

      return user.authenticationToken
    }

    return null;
  })

  const getServerAuthToken = computed((): string | null => {
    const authTokenCookie = useCookie(AUTH_TOKEN_COOKIE_NAME).value ?? null

    return authTokenCookie
  })

  const authToken = computed(() => {
    return process.client ? getClientAuthToken.value : getServerAuthToken.value
  })

  const isAuthenticated = computed(() => {
    return authToken.value != null
  })

  const userCredentials = computed(() => {
    if (authToken.value) {
      return extractUserCredentialsFromJWT(authToken.value)
    } else {
      return null
    }
  })

  const userEmail = computed(() => userCredentials.value?.email)

  const storeAuthToken = (token: string) => {
    const userCredentials = extractUserCredentialsFromJWT(token)

    userLocalStorage.value = JSON.stringify(userCredentials)
    userEmailLocalStorage.value = userCredentials.email
    userEmailHashLocalStorage.value = sha256(userCredentials.email).toString()
    userIdLocalStorage.value = userCredentials.id.toString()

    storeAuthTokenCookie(userCredentials)
  }

  const signIn = async (email: string, password: string) => {
    const signInResponse = await AuthService.signIn({
      emailPassword: {
        email: email.trim(),
        password: password.trim(),
      }
    })

    if (signInResponse.successful) {
      storeAuthToken(signInResponse.token!!)

      gtmPush({ event: "signed-in", userId: userIdLocalStorage.value, email })
    }

    return signInResponse.successful
  }

  const signOut = () => {
    userLocalStorage.value = null
    userEmailLocalStorage.value = null
    userEmailHashLocalStorage.value = null
    userIdLocalStorage.value = null

    clearAuthTokenCookie()

    gtmPush({ event: "signed-out" })
  }

  const refreshAuthToken = async () => {
    if (authToken.value == null) {
      return
    }

    const refreshTokenResponse = await AuthService.refreshToken({
      token: authToken.value!!
    })

    storeAuthToken(refreshTokenResponse.token)
  }

  watch(userLocalStorage, () => {
    gtmPush({ event: "captured-user-id" })
  })

  return {
    isAuthenticated,
    authToken,
    userCredentials,
    userEmail,
    storeAuthToken,
    signIn,
    signOut,
    refreshAuthToken
  }
})
