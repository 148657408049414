import type { FetchError } from 'ofetch'

export interface EmailPasswordCredential {
  email: string,
  password: string
}

export interface ConfirmEmailRequest {
  email: string,
  ticket: string
}

export interface InitiateResetCredentialRequest {
  email: string
}

export interface ResetCredentialRequest {
  email: string,
  ticket: string,
  password: string
}

export interface UserInfo {
  email: string,
  password: string
}

export interface CustomerInfo {
  firstName: string,
  lastName: string,
  email: string,
  birthDate: string,
  cpf: string
}

export interface ProfileAnswer {
  questionId: string,
  choices: string[]
}

export interface ProfileInfo {
  questionnaireId: number,
  answers: ProfileAnswer[]
}

export interface SignUpRequest {
  user: UserInfo,
  customer: CustomerInfo,
  profile: ProfileInfo
}

export interface SignUpResponse {
  successful: boolean,
  conflict: boolean,
  token?: string
}

export interface SignInRequest {
  emailPassword?: EmailPasswordCredential
}

export interface SignInResponse {
  successful: boolean,
  token?: string
}

export interface RefreshTokenRequest {
  token: string
}

export interface TokenResponse {
  token: string
}

export interface Ticket {
  email: string
  ticket: string
}

export function decodeTicket(encodedTicket: string): Ticket {
  const ticketHash = encodedTicket.replace("-", "+").replace("_", "/")
  const decodedTicket = decodeURIComponent(ticketHash)
  const decodedBytes = atob(decodedTicket)
  const [email, ticket] = decodedBytes.split(":")

  return { email, ticket }
}

const authBaseUrl = () => {
  return useConfig().waffleApi
}

export default {
  async signIn(request: SignInRequest): Promise<SignInResponse> {
    try {
      const response = await $fetch("auth/sign-in", {
        baseURL: authBaseUrl(),
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: request
      }) as TokenResponse;

      return { successful: true, token: response.token }
    } catch (error) {
      const fetchError = error as FetchError

      if (fetchError.status) {
        return { successful: false }
      } else {
        throw error
      }
    }
  },
  async refreshToken(request: RefreshTokenRequest): Promise<TokenResponse> {
    return await $fetch("auth/refresh", {
      baseURL: authBaseUrl(),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: request
    })
  },
  async confirmEmail(request: ConfirmEmailRequest): Promise<void> {
    await $fetch("auth/email/confirmation", {
      baseURL: authBaseUrl(),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: request
    })
  },
  async initiateResetCredential(request: InitiateResetCredentialRequest): Promise<void> {
    await $fetch("auth/email/reset", {
      baseURL: authBaseUrl(),
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: request
    })
  },
  async resetCredential(request: ResetCredentialRequest): Promise<void> {
    await $fetch("auth/email/reset", {
      baseURL: authBaseUrl(),
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: request
    })
  }
}