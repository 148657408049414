export const useAppScroll = defineStore('appScroll', () => {
  const scrollEnabled = useState('scrollEnabled', () => true)

  const enableScroll = () => {
    scrollEnabled.value = true
  }

  const disableScroll = () => {
    scrollEnabled.value = false
  }

  return {
    scrollEnabled,
    enableScroll,
    disableScroll
  }
})