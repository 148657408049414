export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[],"style":[],"script":[{"id":"ze-snippet","src":"https://static.zdassets.com/ekr/snippet.js?key=2dde4138-8be2-45f8-8d85-e6de92729a7a"},{"src":"/scripts/hide-zendesk-widget.js"}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null