interface Config {
  env: 'development' | 'homolog' | 'production',
  termsOfUseVersion: string,
  privacyPolicyVersion: string,
  waffleApi: string,
  billingPlatformId: number,
  vindi: {
    apiAddress: string,
    publicKey: string
  },
  sentry?: {
    dsn: string
  },
  gtm?: {
    id: string,
    debug: boolean
  },
  algolia: {
    productIndexName: string,
  },
  ecommerceEnabled: boolean
}

const devConfig: Config = {
  env: 'development',
  termsOfUseVersion: "2019-07-01",
  privacyPolicyVersion: "2019-07-01",
  waffleApi: 'http://localhost:7935/v1/magenta',
  billingPlatformId: 1,
  // gtm: {
  //   id: 'GTM-MM44R65',
  //   debug: true
  // },
  sentry: {
    dsn: 'https://16ee5cff4b08460a98104ca1be41ec32@o264591.ingest.sentry.io/4504953769885696'
  },
  vindi: {
    apiAddress: 'https://sandbox-app.vindi.com.br',
    publicKey: 'HWTourqxXA_6oN6JeFw4V_cRR72Np1jMydkBdoCR5gE'
  },
  algolia: {
    productIndexName: 'dev_product'
  },
  ecommerceEnabled: true
}

const homologConfig: Config = {
  env: 'homolog',
  termsOfUseVersion: "2019-07-01",
  privacyPolicyVersion: "2019-07-01",
  waffleApi: 'https://customer-api.boxmagenta.com.br/v1/magenta',
  billingPlatformId: 2,
  vindi: {
    apiAddress: 'https://app.vindi.com.br',
    publicKey: 'YxI1dXokMARp2mPQD7zWFmHyoTR1KK4LGkWqJ9sm_2o'
  },
  sentry: {
    dsn: 'https://16ee5cff4b08460a98104ca1be41ec32@o264591.ingest.sentry.io/4504953769885696'
  },
  gtm: {
    id: 'GTM-MM44R65',
    debug: true
  },
  algolia: {
    productIndexName: 'products'
  },
  ecommerceEnabled: false
}

const prodConfig: Config = {
  ...homologConfig,
  env: 'production',
  gtm: {
    id: 'GTM-MM44R65',
    debug: false
  },
}

export function useConfig(): Config {
  const runtimeConfig = useRuntimeConfig()

  if (runtimeConfig.public.env == 'production') {
    return prodConfig
  } else if (runtimeConfig.public.env == 'homolog') {
    return homologConfig
  } else {
    return devConfig
  }
}
