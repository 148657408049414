import type { PrimarySubscription } from '~/services/subscription/subscriptionService'

import CustomerService from '~/services/customer/customerService'

import { useAuth } from '~/store/auth'

export const useActiveSubscription = defineStore('subscription', () => {
  const auth = useAuth()

  const initialized = useState(() => false)
  const subscription = useState<PrimarySubscription | null>(() => null)

  const refreshPromise = ref<Promise<PrimarySubscription | null> | null>(null)

  const fetchActiveSubscription = async () => {
    if (auth.isAuthenticated) {
      try {
        return  await CustomerService.findSelfPrimarySubscription()
      } catch (error: any) {
        if ('status' in error && error.status != 404) {
          captureException(new Error('Failed to fetch active subscription', { cause: error }))
        }

        return null
      }
    } else {
      return null
    }
  }

  const refresh = async () => {
    if (refreshPromise.value == null) {
      refreshPromise.value = fetchActiveSubscription().then((activeSubscription) => {
        subscription.value = activeSubscription

        refreshPromise.value = null

        return activeSubscription
      })
    }

    await refreshPromise.value
  }

  const init = async () => {
    if (!initialized.value) {
      await refresh()

      initialized.value = true
    }
  }

  watch(() => auth.userCredentials, refresh)

  return {
    init,
    subscription,
    refresh
  }
})