export type NotificationType = 'info' | 'error' | 'success' | 'warning'

export type NotificationState = 'showing' | 'fading'

export interface Notification {
  id: number,
  type: NotificationType,
  message: string,
  expireAt: number,
  state: NotificationState
}

interface NotificationsState {
  nextId: number,
  notifications: Notification[]
}

export function useNotifications() {
  const state = useState<NotificationsState>(
    'notifications', () => ({
    nextId: 1,
    notifications: []
  }))

  function hide(notificationId: number) {
    const index = state.value.notifications.findIndex((notification) => notification.id === notificationId);

    if (index === -1) return;

    state.value.notifications[index].state = 'fading';

    setTimeout(() => {
      const index = state.value.notifications.findIndex((notification) => notification.id === notificationId);

      if (index === -1) return;

      state.value.notifications.splice(index, 1);
    }, 160);
  }

  function show( message: string, type: NotificationType = 'info', timeout: number = 3000) {
    const id = state.value.nextId++

    const notification: Notification = {
      id, type, message, expireAt: Date.now() + timeout, state: 'showing'
    }

    state.value.notifications.push(notification)

    setTimeout(() => {
      hide(id)
    }, timeout)
  }

  const success = (message: string, timeout: number = 3000) => show(message, 'success', timeout)
  const error = (message: string, timeout: number = 3000) => show(message, 'error', timeout)
  const warning = (message: string, timeout: number = 3000) => show(message, 'warning', timeout)
  const info = (message: string, timeout: number = 3000) => show(message, 'info', timeout)

  return {
    notifications: readonly(state.value.notifications),
    hide,
    show,
    success,
    error,
    warning,
    info
  }
}