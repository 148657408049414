<template lang="pug">
.notifications
  .notification(
    v-for="notification in notificationList",
    :key="notification.id",
    :class="{ fading: isFading(notification), error: isError(notification), warning: isWarning(notification), success: isSuccess(notification) }"
  )
    .message {{ notification.message }}
    .close-button(@click="hideNotification(notification.id)")
</template>
<script setup lang="ts">
import { defineComponent } from 'vue'

import type { Notification } from '~/composables/notifications'

const notifications = useNotifications()

const notificationList = computed(() => notifications.notifications)

const isFading = (notification: Notification) => notification.state == 'fading'
const isError = (notification: Notification) => notification.type == 'error'
const isSuccess = (notification: Notification) => notification.type == 'success'
const isWarning = (notification: Notification) => notification.type == 'warning'

const hideNotification = (id: number) => notifications.hide(id)
</script>
<style lang="sass" scoped>
@import '~/assets/styles/variables.sass'
@import '~/assets/styles/mixins.sass'

.notifications
  position: fixed
  top: 16px
  left: 0
  width: 100vw
  display: flex
  flex-direction: column
  align-items: center
  z-index: 200000
  pointer-events: none

  .notification
    display: flex
    width: 420px
    min-height: 24px
    background-color: $acquaBlue
    margin: 8px
    padding: 8px
    border-radius: 8px
    box-sizing: border-box
    color: black
    font-family: $primary-font-family
    box-shadow: 0 2px 16px adjust-color($acquaBlue, $lightness: -40%, $alpha: -0.5)
    animation: fade-in .16s cubic-bezier(0.34, 1.56, 0.64, 1) forwards
    pointer-events: all

    .close-button
        filter: invert(1)

    &.error
      background-color: $darkMagenta
      color: white
      box-shadow: 0 2px 16px adjust-color($darkMagenta, $lightness: -40%, $alpha: -0.5)

      .close-button
        filter: invert(0)

    &.warning
      color: black
      background-color: #ffcc00
      box-shadow: 0 2px 16px adjust-color(#ffcc00, $lightness: -10%, $alpha: -0.5)

      .close-button
        filter: invert(1)

    &.fading
      animation: fade-out .16s ease-in-out forwards

    @keyframes fade-in
      from
        opacity: 0
        transform: scale(0.8)

      to
        opacity: 1
        transform: scale(1)


    @keyframes fade-out
      to
        opacity: 0
        transform: scale(0.8)

      from
        opacity: 1
        transform: scale(1)

    .message
      flex-grow: 1
      padding: 8px

    .close-button
      cursor: pointer
      width: 48px
      margin-right: 2px
      flex-shrink: 0
      border-left: 1px solid white
      background-image: url("/icons/close-button-icon.svg")
      background-repeat: no-repeat
      background-size: 24px 24px
      background-position: center

  @include upperBreakpoint(mobileonly)
    .notification
      width: 96%
      margin: 4px 0
</style>