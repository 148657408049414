import { useGtm } from "@gtm-support/vue-gtm";
import type { DataLayerObject } from "@gtm-support/vue-gtm";

export function gtmPush(...items: DataLayerObject[]) {
  const gtm = useGtm()

  if (gtm != null) {
    const dataLayer = gtm.dataLayer()
    if (dataLayer) {
      dataLayer.push(...items);
    }
  }
}
