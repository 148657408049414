export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('app:error', (error) => {
    console.error("Nuxt app:error: ", error)
  })

  nuxtApp.hook('vue:error', (error) => {
    console.error("Nuxt vue:error: ", error)
    clearError()
  })
})
