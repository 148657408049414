import type { RouterConfig, } from "@nuxt/schema"

type ScrollPosition = { left?: number, top?: number, behavior?: ScrollBehavior }

export default <RouterConfig> {
  scrollBehavior(to, from, savedPosition) {
    var scrollPosition: ScrollPosition = { left: 0, top: 0 }

    switch(to.name) {
      case "edit-profile":
        scrollPosition = ({ left: 0, top: 0, behavior: 'smooth' })
      case "subscription-register":
        scrollPosition = ({ left: 0, top: 0, behavior: 'smooth' })
      default:
        if (savedPosition) {
          scrollPosition = { top: savedPosition.top, left: savedPosition.left, behavior: savedPosition.behavior }
        } else {
          scrollPosition = ({ top: 0, left: 0 })
        }
    }

    if (process.client) {
      // @ts-ignore
      window.__NUXT__SCROLL_POSITION = scrollPosition
      return ({})
    } else {
      return scrollPosition
    }
  }
}